.app__main-vision {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    margin-top: 50px;
  }

  .heading {
    font-weight: 600;
    font-size: 52px;
    line-height: 60px;
    color: #18161D;
  }

  .content {
    margin: 40px 0 0 0;
    font-weight: 700;
    font-size: 58px;
    text-align: center;
    color: #51575D;
    width: 1200px;
    height: 500px;
    @media screen and (max-width: 1200px) {
      font-size: 48px;
      width: 1000px;
      height: 400px;
    }
    @media screen and (max-width: 895px) {
      font-size: 40px;
      width: 800px;
      height: 400px;
    }
    @media screen and (max-width: 746px) {
      font-size: 32px;
      width: 600px;
      height: 400px;
    }
    @media screen and (max-width: 597px) {
      font-size: 24px;
      width: 400px;
      height: 400px;
    }
  }
}
