/*
 * STYLES
 */

.contact__mainWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .contactUs__main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contactUs__main-homepage {
      background-repeat: no-repeat !important;
      background-size: cover !important;
      height: 60vh;
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contactUs__main-homepage-heading {
        font-weight: 600;
        font-size: 64px;
        line-height: 96px;
        color: #18161D;
        @media screen and (max-width: 642px) {
          font-size: 52px;
        }
      }

      .contactUs__main-homepage-subHeading {
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        width: 623px;
        height: 56px;
        color: #51575D;
        text-align: center;
        @media screen and (max-width: 642px) {
          width: 423px;
        }
      }
    }

    .contactForm__wrapper, .contactInfo__wrapper {
      margin-top: 100px;
      align-items: center;
      display: flex;
      width: 100vw;
      justify-content: center;
    }
  }
}
