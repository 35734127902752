/*
 * STYLES
 */

.meet__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  .meet__main-heading {
    font-weight: 600;
    font-size: 40px;
    @media screen and (max-width: 504px) {
     font-size: 32px;
    }
  }

  .meet__main-teamWrapper {
    margin: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .meet__main-backgroundBlack {
      background-color: #000000 !important;
      border-radius: 30px;
      margin: 30px;
      width: fit-content;
      height: fit-content;
      border: none;
      display: flex;
      flex-direction: column;
      .meet__main-team {
        transition: all 1s;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        width: 352px;
        height: 454px;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1436px) {
          width: 300px;
          height: 400px;
        }
        @media screen and (max-width: 1280px) {
          width: 250px;
          height: 350px;
        }
        .meet__main-teamWorker-name{
         display: none;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          margin: 10px;
        }
        .meet__main-teamWorker-profession{
          display: none;
          font-weight: 500;
          font-size: 12px;
          color: #FFFFFF;
          margin: 10px;
        }
        &:hover {
          opacity: 0.5;

          .meet__main-teamWorker-name{
            display: contents;
          }
          .meet__main-teamWorker-profession{
            display: contents;
          }
        }
      }
    }
  }
}
