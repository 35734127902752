/*
 * STYLES
 */

.career__main{
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  margin: 80px 0 80px 0;
  @media screen and (max-width: 557px) {
    align-items: center;
    text-align: center;
  }

  justify-content: space-evenly;
  .job__main-wrapper{
    margin-top: 150px;
  }
  .career__main-headingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .career__main-heading {
      font-weight: 600;
      font-size: 50px;
      line-height: 75px;
      margin: 30px;
      @media screen and (max-width: 557px) {
        font-size: 40px;
        line-height: 50px;
      }
    }

    .career__main-subHeading {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      margin: 30px;
    }
  }
  button{
    background: #0031DE;
    color: #FFFFFF;
    width: 166px;
    height: 52px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
  }
  .career__main-imageWrapper{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    @media screen and (max-width: 557px) {
      flex-direction: row;
    }
    .career__main-imageWrapperPrimary{
      display: flex;
      justify-content: flex-end;
      margin: 20px -250px 20px 20px;
      @media screen and (max-width: 1138px) {
        margin: 10px -100px 10p 10px;
      }
      @media screen and (max-width: 803px) {
        margin: 10px;
      }
      @media screen and (max-width: 557px) {
        flex-direction: column;
      }
    }
    .career__main-imageWrapperSecondary{
      display: flex;
      margin: 20px 20px 20px -80px;
      justify-content: flex-start;
      @media screen and (max-width: 1138px) {
        margin: 10px 10px 10px -40px;
      }
      @media screen and (max-width: 803px) {
        margin: 10px;
      }
      @media screen and (max-width: 557px) {
        flex-direction: column;
      }
    }
    .career__main-image{
      width: 400px;
      height: 200px;
      background-size: contain!important;
      background-repeat: no-repeat!important;
      @media screen and (max-width: 1138px) {
        width: 280px;
        height: 150px;
      }
      @media screen and (max-width: 803px) {
        width: 180px;
        height: 100px;
      }
      @media screen and (max-width: 557px) {
        width: 200px;
        height: 150px;
      }
      @media screen and (max-width: 428px) {
        width: 150px;
        height: 100px;
      }
    }
  }
}
