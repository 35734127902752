/*
 * STYLES
 */

.testimonial__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 200px;

  .testimonial__main-header {
    margin-top: 100px;
    font-weight: 600;
    font-size: 48px;
    color: #272E35;
    width: 670px;
    height: 112px;
    @media screen and (max-width: 600px) {
      font-size: 38px;
    }
    @media screen and (max-width: 400px) {
      font-size: 28px;
      width: 338px;
    }
  }
}

.testimonial__main-contentWrapper {
  display: flex;
  flex-direction: column;
  margin: 150px 110px 110px 84px;

  .testimonial__main-content {
    font-weight: 600;
    font-size: 24px;
    width: 630px;
    @media screen and (max-width: 630px) {
      width:400px;
      font-size: 20px;
    }
    @media screen and (max-width: 400px) {
      font-size: 16px;
      width: 300px;
    }
  }

  div:nth-child(2) {
    margin: 20px;
  }
}

.testimonial__clientWrapper {
  display: flex;
  flex-direction: column;

  div:nth-child(1) {
    font-weight: 600;
    font-size: 18px;
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  div:nth-child(2) {
    font-weight: 500;
    font-size: 16px;
    color: #757575;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }
}

.pagination-button {
  display: flex;
  button{
    background: transparent;
    border: none;
    font-size: 34px;
    margin: 10px 20px 10px 20px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      font-size: 30px;
    }
    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
  }
}
