/*
 * STYLES
 */

.aboutContent__main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;

  @media screen and (max-width: 1100px) {
    margin-right: 0 !important;
    align-items: center;
    text-align: center;
  }
  .aboutContent__main-secondary {
    flex-direction: row-reverse !important;
    @media screen and (max-width: 1432px) {
      flex-direction: row !important;
    }
    @media screen and (max-width: 1100px) {
      flex-direction: column !important;
    }

    .aboutContent__main-contentWrapper {
      margin-right: 200px !important;
      @media screen and (max-width: 1432px) {
        margin-right: 0 !important;
      }
    }
  }

  .aboutContent__main-primary {
    display: flex;
    margin: 20px;
    padding: 100px;
    justify-content: center;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }

    .aboutContent__main-imageWrapper {
      margin: 0 100px 0 0;
      @media screen and (max-width: 1100px) {
        margin: 0;
        display: flex;
        justify-content: center;
      }
      .aboutContent__main-image {
        width: 600px;
        height: 424px;
        border-radius: 24px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        @media screen and (max-width: 1618px) {
          width: 400px;
          height: 242px;
        }
        @media screen and (max-width: 504px) {
          width: 300px;
          height: 180px;
        }
      }
    }

    .aboutContent__main-contentWrapper {
      display: flex;
      flex-direction: column;
      width: 502px;
      height: 224px;
      @media screen and (max-width: 1100px) {
        margin:20px;
      }
      @media screen and (max-width: 504px) {
        width: 300px;
        height: 180px;
      }
      .aboutContent__main-heading {
        font-weight: 600;
        font-size: 40px;
        @media screen and (max-width: 504px) {
         font-size: 32px;
        }
      }

      .aboutContent__main-subHeading {
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: #51575D;
        @media screen and (max-width: 504px) {
         font-size: 10px;

        }
      }
    }
  }
}
