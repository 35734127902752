/*
 * STYLES
 */

.about__Main{
  display: flex;
  flex-direction: column;

  .about__main-homepage{
    background-repeat: no-repeat!important;
    background-size: cover!important;
    height: 60vh;
    width: 100vw;
    div{
      display: flex;
      padding-top: 230px;
      justify-content: center;
      font-weight: 600;
      font-size: 64px;
      line-height: 96px;
      }
    }
}
