.app__main-incorporation {
  margin: 50px 50px 200px 50px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 704px) {
    margin: 50px;
  }
  @media screen and (max-width: 509px) {
    margin: 5px;
  }
  .incorporation-headingWrapper {
    margin: 50px 50px 150px 50px;

    text-align: center;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 704px) {
      margin: 50px;
    }
    @media screen and (max-width: 509px) {
      margin: 5px;
    }
    .heading {
      margin: 20px;
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      color: #18161D;
    }

    .main_content {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      color: #51575D;
    }
  }

  .app__main-iso {
    margin: 20px 20px 50px 200px;

    display: flex;

    @media screen and (max-width: 1100px) {
      margin: 20px 20px 50px 100px;
    }
    @media screen and (max-width: 960px) {
      margin: 20px 20px 50px 50px;
    }
    @media screen and (max-width: 704px) {
      flex-direction: column-reverse;
      align-items: center;
    }
    @media screen and (max-width: 509px) {
      margin: 10px;
    }
    .iso-headingWrapper {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 704px) {
        text-align: center;
      }
      .heading {
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: #18161D;
      }

      .content {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: #51575D;
        width: 580px;
        height: 56px;
        @media screen and (max-width: 960px) {
          width: 380px;
        }
      }
    }

    .iso-image {
      width: 153px;
      height: 153px;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background: #6b7688;
    }
  }
}
