/*
 * STYLES
 */

.about__mainWrapper{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.about__main {
  background: #F7F7F8;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 80px 0 80px 150px;
  margin: 140px 30px 30px 30px;
  display: flex;
  width: 1400px;
  @media screen and (max-width: 1465px) {
    width: 1200px;
    height: 700px;
  }
  @media screen and (max-width: 1316px) {
    width: 1100px;
    height: 600px;
    padding: 80px 20px 20px 100px;
  }
  @media screen and (max-width: 1200px) {
    width: 900px;
    padding: 80px 80px 80px 100px;
  }
  @media screen and (max-width: 995px) {
    width: 800px;
    height: 500px;
    padding: 30px 0 30px 60px;
  }
  @media screen and (max-width: 852px) {
    width: 600px;
    height: 400px;
    padding: 30px 0 30px 60px;
  }
  @media screen and (max-width: 679px) {
    width: 380px;
    height: 380px;
    padding: 10px;
    margin-left: 50px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  @media screen and (max-width: 587px) {
    margin: 140px 10px 30px 10px;
    width: 300px;
    height: 300px;
  }
  .about__main-content {
    display: flex;
    flex-direction: column;
    width: 400px;
    @media screen and (max-width: 1465px) {
      width: 300px;
    }
    @media screen and (max-width: 1200px) {
      width: 300px;
    }
    @media screen and (max-width: 995px) {
      width: 260px;
    }
    @media screen and (max-width: 679px) {
     width: 350px;
     align-items: center;
    }
    div:nth-child(1) {
      color: #748FB5;
      font-size:14px;
      line-height: 18px;
      font-weight: 700;
      margin: 20px 0 20px 0;
      @media screen and (max-width: 995px) {
        font-size:12px;
        margin: 5px 0 5px 0;
      }
    }
    div:nth-child(2){
      color: #272E35;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      margin: 20px 0 20px 0;
      @media screen and (max-width: 1200px) {
        font-size: 38px;
      }
      @media screen and (max-width: 995px) {
       font-size: 32px;
        margin: 5px 0 5px 0;
      }
      @media screen and (max-width: 995px) {
        font-size: 32px;
        margin: 5px 0 5px 0;
      }
      @media screen and (max-width: 852px) {
        font-size: 22px;
        margin: 0;
      }

    }
    div:nth-child(3){
      font-weight: 500;
      font-size: 16px;
      color: #6E757C;
      margin: 20px 0 20px 0;
      @media screen and (max-width: 1200px) {
       font-size: 14px;
        margin: 5px 0 5px 0;
      }
      @media screen and (max-width: 852px) {
        font-size: 12px;
        margin: 0;
      }

    }
    button{
      border: 2px solid #272E35;
      border-radius: 10px;
      background-color: transparent;
      color: #272E35;
      padding: 10px;
      font-size: 16px;
      font-weight: 700;
      width: 150px;
      margin: 20px 0 20px 0;
      cursor: pointer;
      @media screen and (max-width: 1200px) {
        width: 100px;
        font-size: 12px;
      }
      @media screen and (max-width: 995px) {
        width: 100px;
        font-size: 10px;
        height: 40px;
      }
      @media screen and (max-width: 679px) {
        width: 90px;
        font-size: 8px;
      }
    }
  }
  .about__main-image{
    margin: 30px 0 0 80px;
    background-size: contain!important;
    background-repeat: no-repeat!important;
    border-radius: 24px;
    width: 550px;
    height: 450px;
    @media screen and (max-width: 1316px) {
     width: 500px;
      height: 400px;
    }
    @media screen and (max-width: 1200px) {
      width: 450px;
      height: 350px;
    }
    @media screen and (max-width: 995px) {
      width: 350px;
      height: 250px;
    }
    @media screen and (max-width: 852px) {
      width: 250px;
      height: 150px;
      margin: 80px 0 0 40px;
    }
    @media screen and (max-width: 679px) {
      display: none;
    }
  }

}
