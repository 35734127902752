.job-apply__main {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .job-apply__main-buttonWrapper {
    text-align: left;
    width: 70vw;
    margin: 10px 10px 50px 10px;
    @media screen and (max-width: 810px) {
      width: 90vw;
    }
    .job-apply__main-buttonOpening {
      background: #EBECF0;
      border-radius: 25px;
      color: #8C9FC8;
      border: none;
      font-size: 14px;
      padding: 15px;
    }

  }

  .job-apply__main-homeWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .job-apply__main-field {
      font-size: 16px;
      font-weight: 600;
      padding: 10px 15px 10px 15px;
      border: 2px solid #4BC14E;
      color: #4BC14E;
      border-radius: 25px;
      background: transparent;
    }

    .job-apply__main-jobProfile {
      font-size: 44px;
      font-weight: 500;
      margin: 20px;
      @media screen and (max-width: 481px) {
        font-size: 32px;
      }
    }

    .job-apply__main-location {
      color: rgba(13, 38, 76, .71) !important;
      font-weight: 400;
      font-size: 18px;
      margin: 0 20px 20px 20px;
    }

    .job-apply__main-expectation {
      max-width: 60%;
      text-align: center;
      font-size: 18px;
      line-height: 1.9;
      font-weight: 400;
      @media screen and (max-width: 810px) {
       max-width: 80%;
      }
      @media screen and (max-width: 481px) {
        max-width: 90%;
        font-size: 16px;
      }
    }

    .job-apply__main-apply {
      background: transparent;
      border-radius: 6px;
      padding: 10px 15px 10px 15px;
      font-size: 16px;
      margin: 20px;
      border: 2px solid #2C94F2;
      color: #2C94F2;

      &:hover {
        transition: all 0.2s ease-in-out;
        background: #2C94F2;
        color: #FFFFFF;
      }

    }
  }

  .job-apply__main-jobInfoWrapper {
    display: flex;
    flex-direction: column;
    width: 70vw;
    margin-top: 150px;

    .job-apply__main-aboutJobWrapper {
      display: flex;
      flex-direction: column;

      .job-apply__main-aboutJobHeading {
        font-size: 22px;
        font-weight: 400;
        margin: 20px 0 20px 0;
      }

      .job-apply__main-aboutJob {
        font-size: 16px;
        font-weight: 400;
        margin: 20px 0 20px 0;
        color: rgba(13, 38, 76, .71) !important;
      }
    }

    .job-apply__main-pointsWrapper {

      display: flex;
      margin-top: 40px;
      @media screen and (max-width: 810px) {
       flex-direction: column;
      }
      .job-apply__main-roleWrapper {
        display: flex;
        flex-direction: column;
        width: 50%;
        @media screen and (max-width: 810px) {
          width: 100%;
        }
        .job-apply__main-roleHeading {
          font-size: 20px;
          font-weight: 300;
          margin: 20px 0 20px 0;
        }

        .job-apply__main-roles {
          li {
            font-weight: 400;
            font-size: 16px;
            color: rgba(13, 38, 76, .71) !important;
            margin: 10px 0 10px 0;
          }
        }
      }

      .job-apply__main-expectation {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-left: 50px;
        @media screen and (max-width: 810px) {
          width: 100%;
          margin-left: 0;
        }
        .job-apply__main-expectationHeading {
          font-size: 20px;
          font-weight: 300;
          margin: 20px 0 20px 0;
        }

        .job-apply__main-expectations {
          li {
            font-weight: 400;
            font-size: 16px;
            color: rgba(13, 38, 76, .71) !important;
            margin: 10px 0 10px 0;
          }
        }
      }
    }
  }

  .quick__apply {
    margin: 200px 0 100px 0;
    width: 988px;
    height: 626px;
    @media screen and (max-width: 1000px) {
      width: 800px;
    }
    @media screen and (max-width: 810px) {
      width: 600px;
      margin: 200px 0 200px 0;
    }
    @media screen and (max-width: 584px) {
      width: 400px;
      margin: 200px 0 250px 0;
    }
  }
}
