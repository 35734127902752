/*
 * STYLES
 */
.contact{
  padding: 80px;
.contact__main {
  display: flex;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 1400px;
  height: 600px;
  background: rgb(179, 121, 189);
  background: linear-gradient(152deg, rgba(179, 121, 189, 1) 0%, rgba(53, 52, 139, 1) 15%, rgba(44, 53, 61, 1) 28%, rgba(44, 53, 61, 1) 100%);
  border-radius: 30px;
  margin: 50px;
  padding:30px;
  @media screen and (max-width: 1410px) {
    width: 1000px;
    height: 500px;
  }
  @media screen and (max-width: 1000px) {
    width: 700px;
    height: 400px;
  }
  @media screen and (max-width: 700px) {
    width: 600px;
    height: 400px;
  }
  @media screen and (max-width: 600px) {
    width: 500px;
    height:400px;
    padding: 20px;
  }
  @media screen and (max-width: 498px) {
    width: 400px;
    height:300px;
    padding: 20px;
  }
  @media screen and (max-width: 394px) {
    width: 350px;
    height:250px;
    padding: 10px;
  }
  @media screen and (max-width: 368px) {
    width: 320px;
    height:220px;
    padding: 10px;
  }
  .contact__main-content {
    display: flex;
    flex-direction: column;
    margin: 50px 100px 50px 150px;
    width: 600px;
    justify-content: space-evenly;
    @media screen and (max-width: 1410px) {
      margin: 50px 0 50px 40px;
    }
    @media screen and (max-width: 1000px) {
      margin: 50px 0 50px 20px;
    }
    div:nth-child(1) {
      font-weight: 600;
      font-size: 80px;
      color: #FFFFFF;
      line-height: 78px;
      @media screen and (max-width: 1000px) {
        font-size: 52px;
        line-height: 56px;
      }
      @media screen and (max-width: 700px) {
        font-size: 42px;
        line-height: 48px;
      }
      @media screen and (max-width: 600px) {
        font-size: 22px;
        line-height: 42px;
      }
      @media screen and (max-width: 498px) {
        font-size: 20px;
        line-height: 32px;
      }
    }

    div:nth-child(2) {
      font-weight: 500;
      font-size: 18px;
      color: #6A7986;
      @media screen and (max-width: 1000px) {
        font-size: 16px;
      }
      @media screen and (max-width: 700px) {
        margin-top: 10px;
        font-size: 14px;
      }
      @media screen and (max-width: 498px) {
        margin-top: 10px;
        font-size: 12px;
      }
    }

    button {
      width: 118px;
      height: 56px;
      background: #0031DE;
      border-radius: 16px;
      border: none;
      font-weight: 800;
      font-size: 16px;
      color: #FFFFFF;
      cursor: pointer;
      @media screen and (max-width: 1000px) {
        height: 36px;
        font-size: 14px;
      }
      @media screen and (max-width: 1000px) {
        margin-top: 10px;
        height: 32px;
        font-size: 12px;
      }
      @media screen and (max-width: 498px) {
        width: 80px;
        margin-top: 10px;
        height: 32px;
        font-size: 10px;
      }
    }
  }

  .contact__main-image {
    padding: 0 50px 20px 0;
    div:nth-child(1) {
      background-size: cover !important;
      background-repeat: no-repeat !important;
      width: 500px;
      height: 400px;
      margin: 30px 0 0 38px;
      border-radius: 20px;
      @media screen and (max-width: 1410px) {
        width: 420px;
        height: 320px;
      }
      @media screen and (max-width: 1000px) {
        width: 320px;
        height: 220px;
      }
      @media screen and (max-width: 700px) {
        width: 280px;
        height: 180px;
        margin: 55px 0 0 38px;
      }
      @media screen and (max-width: 600px) {
        width: 250px;
        height: 150px;
        margin: 75px 0 0 30px;
      }
      @media screen and (max-width: 498px) {
        width: 200px;
        height: 150px;
        margin: 50px 0 0 30px;
      }
      @media screen and (max-width: 394px) {
        width: 150px;
        height:150px;
        margin: 50px 0 0 30px;
      }
      @media screen and (max-width: 368px) {
        width: 150px;
        height:130px;
        margin: 30px 0 0 30px;
      }
    }
  }
}
}
