/*
 * STYLES
 */
.contactInfo__main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;

  .contactInfo__main {
    display: flex;
    margin: 50px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1082px) {
      flex-direction: column;
      margin: 0;
      justify-content: center;
      align-items: center;
    }


    .contactInfo__main-infoWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0 80px 0 80px;
      margin: 40px;
      @media screen and (max-width: 1610px) {
        margin: 20px;
        padding: 0 40px 0 40px;
      }
      @media screen and (max-width: 1346px) {
        margin: 10px;
        padding: 0 10px 0 10px;
      }
      @media screen and (max-width: 1170px) {
        padding: 0 10px 0 10px;
      }
      @media screen and (max-width: 1082px) {
        margin: 50px;
      }

      .country__headingWrapper {
        display: flex;
        justify-content: center;
        text-align: center;

        .country_heading {
          font-size: 28px;
          font-weight: 600;
          color: #030303;
        }
      }

      .address__wrapper, .phone__wrapper, .email__wrapper {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .address_icon, .phone__icon, .email__icon {
          width: 50px;
          height: 50px;
          background-repeat: no-repeat !important;
          background-size: cover !important;
          margin: 20px;
          @media screen and (max-width: 1204px) {
            margin: 10px;
          }
        }

        .address, .phone, .email {
          width: 250px;
          font-size: 16px;
          margin: 20px;
          height: fit-content;
          @media screen and (max-width: 1204px) {
            margin: 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
