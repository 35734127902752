/*
 * STYLES
 */

.resources__wrapper {
  display: flex;
  border-radius: 15px;
  margin: 150px 30px 0 30px;
  padding: 100px 0 0 160px;
  background: rgb(174, 128, 220);
  background: linear-gradient(133deg, rgba(174, 128, 220, 1) 0%, rgba(33, 31, 36, 1) 25%, rgba(18, 18, 42, 1) 68%, rgba(128, 132, 220, 1) 100%);
  width: 1400px;
  height: 600px;
  z-index: 3;
  @media screen and (max-width: 1468px) {
    width: 1200px;
    height: 550px;
    padding: 80px 0 0 120px;
    margin: 80px 20px 0 15px;
  }
  @media screen and (max-width: 1316px) {
    width: 1100px;
    height: 500px;
    padding: 70px 0 0 110px;
    margin: 80px 20px 0 15px;
  }
  @media screen and (max-width: 1236px) {
    width: 1000px;
    height: 500px;
    padding: 60px 0 0 100px;
    margin: 80px 10px 0 10px;
  }
  @media screen and (max-width: 1000px) {
    width: 850px;
    height: 320px;
    padding: 40px 0 0 80px;
    margin: 80px 10px 0 10px;
  }
  @media screen and (max-width: 879px) {
    width: 650px;
    height: 300px;
    padding: 40px 0 0 80px;
    margin: 80px 10px 0 10px;
  }
  @media screen and (max-width: 656px) {
    width: 300px;
    height: 360px;
    padding: 20px 0 0 40px;
    margin: 80px 10px 0 10px;
    flex-direction: column;
  }
  .resources__image {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 600px;
    height: 600px;
    border-radius: 24px;
    @media screen and (max-width: 1468px) {
      width: 550px;
      height: 550px;
    }
    @media screen and (max-width: 1236px) {
      width: 500px;
      height: 500px;
    }
    @media screen and (max-width: 1000px) {
     width: 400px;
      height: 400px;
    }
    @media screen and (max-width: 656px) {
      width: 200px;
      height: 150px;
    }

  }

  .resources__contentWrapper {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-left: 100px;
    @media screen and (max-width: 1000px) {
      width: 42%;
    }
    @media screen and (max-width: 879px) {
      width: 62%;
    }
    @media screen and (max-width: 656px) {
      width: 100%;
      margin-left: 0;
      padding: 10px;
    }
    div {
      font-weight: 700;
      font-size: 64px;
      color: #ffffff;
      margin-bottom: 30px;
      @media screen and (max-width: 1316px) {
        font-size: 58px;
        margin-bottom: 20px;
        line-height: 44px;
      }
      @media screen and (max-width: 1236px) {
        font-size: 52px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 42px;
        margin: 0 0 10px 0;
      }
      @media screen and (max-width: 879px) {
        font-size: 32px;
      }
      @media screen and (max-width: 656px) {
        font-size: 18px;
        margin: 0;
      }
    }

    p {
      margin-bottom: 20px;
      line-height: 28px;
      @media screen and (max-width: 1316px) {
        line-height: 24px;
      }
      @media screen and (max-width: 1236px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 12px;
        line-height: 20px;
      }
      @media screen and (max-width: 879px) {
        font-size: 10px;
        line-height: 16px;
      }
      @media screen and (max-width: 656px) {
        font-size: 10px;
      }
    }

    button {
      border: 2px solid #ffffff;
      color: #ffffff;
      background: transparent;
      border-radius: 10px;
      width: 150px;
      height: 50px;
      cursor: pointer;
      @media screen and (max-width: 1236px) {
        width: 100px;
        height: 40px;
        font-size: 12px;
      }
      @media screen and (max-width: 879px) {
        width: 100px;
        height: 30px;
        font-size: 10px;
      }
      @media screen and (max-width: 656px) {
       width: 120px;
        height: 20px;
        font-size: 8px;
      }
    }
  }
}
.carousel-item{
  transition: transform 0.6s ease-in-out !important;
}
