*.capability__main {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  div:nth-child(1) {
    color: #748FB5;
    font-size: 14px;
    font-weight: 700;

  }

  div:nth-child(2) {
    font-size: 48px;
    font-weight: 600;
    color: #272E35;
    @media screen and (max-width: 852px) {
      font-size: 38px;
    }
    @media screen and (max-width: 452px) {
      font-size: 30px;
    }
  }

  .capability__main-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media screen and (min-width: 1100px) {
      margin-right: 80px;
    }
    .capability__main-subContent {
      display: flex;
      flex-direction: column;
      margin: 80px 60px 60px 60px;
      width: 220px;
      height: 220px;
      border-bottom: 3px solid rgba(116, 143, 181, 0.15);
      align-items: center;
      @media screen and (max-width: 852px) {
       width: 150px;
        height: 150px;
        margin: 40px 30px 30px 30px;
      }
      div:nth-child(1) {
        background-repeat: no-repeat!important;
        background-size: contain!important;
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
        @media screen and (max-width: 852px) {
          width: 30px;
          height: 30px;
        }
      }

      div:nth-child(2) {
        font-weight: 600;
        font-size: 20px;
        color: #272E35;
        text-align: center;
        @media screen and (max-width: 852px) {
          font-size: 15px;
        }
      }
    }
  }
}
