// mixin multiple variable
@mixin inputName {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  width: 372px;
  height: 49px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #A3A4A9;
  @media screen and (max-width: 1000px) {
    width: 272px;
  }
  @media screen and (max-width: 812px) {
    width: 100%;
  }
}

@mixin placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #A3A4A9;
  padding: 10px;
}

@mixin label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@mixin select {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

// Styling
.app__main-quickApply {
  display: flex;
  flex-direction: column;
  background: #FAFAFA;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  width: 100%;
  height: 100%;
  padding: 50px;

  .app__main_headingWrapper {
    display: flex;
    flex-direction: column;

    .app__main-heading {
      font-weight: 400;
      font-size: 40px;
      line-height: 60px;
      text-align: center;
      color: #0d7be0;
    }

    .app__main-content {
      color: rgba(13, 38, 76, .71) !important;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      margin: 20px 0 20px 0;
    }

  }

  form {
    display: flex;
    flex-direction: column;

    .app__main-formWrapper {

      display: flex;
      @media screen and (max-width: 812px) {
       flex-direction: column;
      }

      .firstHalf {
        display: flex;
        flex-direction: column;
        flex: 1;

        .inputWrapper_name, .inputWrapper_mobile {
          margin: 10px;
          display: flex;
          flex-direction: column;

          label {
            @include label;
          }

          input {
            @include inputName;


            &::-webkit-input-placeholder { /* Edge */
              @include placeholder;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              @include placeholder;
            }

            &::placeholder {
              @include placeholder;
            }
          }
        }

        .inputWrapper_graduation {
          display: flex;
          flex-direction: column;
          margin: 10px;

          label {
            @include label;
            padding: 10px;
          }

          select {
            @include select;
            width: 372px;
            height: 49px;
            @media screen and (max-width: 1000px) {
              width: 272px;
            }
            @media screen and (max-width: 812px) {
              width: 100%;
            }
            option {
              @include select;
              width: 372px;
              height: 49px;
              @media screen and (max-width: 1000px) {
                width: 272px;
              }
              @media screen and (max-width: 812px) {
                width: 100%;
              }
            }
          }
        }
      }

      .secondHalf {
        display: flex;
        flex-direction: column;
        flex: 1;

        .inputWrapper_email, .inputWrapper_cv {
          display: flex;
          flex-direction: column;
          margin: 10px;

          label {
            @include label;
          }

          input {
            @include inputName;

            &::file-selector-button {
              width: 157px;
              height: 49px;
              cursor: pointer;
              background: #E1E1E1;
              border: 1px solid rgba(0, 0, 0, 0.07);
              border-radius: 4px;
              @media screen and (max-width: 1000px) {
               width: 100px;
               font-size: 14px;
              }
            }

            &::-webkit-input-placeholder { /* Edge */
              @include placeholder;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              @include placeholder;
            }

            &::placeholder {
              @include placeholder;
            }
          }
        }

        .inputWrapper_ctc {
          display: flex;
          flex-direction: column;

          label {
            @include label;
          }
        }

        div {
          display: flex;
          margin-top: 10px;

          .inputWrapper_lakhsWrapper, .inputWrapper_thousandWrapper {


            select {
              @include select;
              width: 102px;
              height: 49px;
              @media screen and (max-width: 812px) {
                width: 100%;
              }
              option {
                @include select;
                width: 102px;
                height: 49px;
              }
              @media screen and (max-width: 812px) {
                width: 100%;
              }
            }
          }
        }
      }


    }

    .button_wrapper {
      text-align: center;

      .submit {
        margin-top: 40px;
        border-radius: 4px;
        width: 100%;
        height: 50px;
        background: #0d7be0;
        border: none;
        font-size: 18px;
        color: #FFFFFF;

        &:hover {
          background: #002B9A;
          transition: all .3s cubic-bezier(.165, .84, .44, 1);
        }
      }
    }
  }
}
