.completed_project__main{
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .completed_project__main_heading{
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-align: center;
    @media screen and (max-width: 600px) {
      font-size: 42px;
      line-height: 50px;
    }
  }
  .completed_project {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 80px;
    width: 100vw;
    flex-wrap: wrap;
    height: fit-content;

    .completed_project__wrapper {
      display: flex;
      flex-direction: column;
      margin: 20px;
      width: 495px;
      @media screen and (max-width: 1300px) {
        width: 295px;
      }
      .completed_project__image {
        border-radius: 20px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        width: 495px;
        height: 338px;
        @media screen and (max-width: 1300px) {
          width: 295px;
          height: 238px;
        }
      }

      .completed_project__date {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: #002B9A;
      }

      .completed_project__heading {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
      }

      .completed_project__content {
        overflow: hidden;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        width: 495px;
        height: 338px;
        @media screen and (max-width: 1300px) {
          width: 295px;
          height: fit-content;
        }
      }
    }
  }
}
