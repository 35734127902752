.certificate__main{
margin-top: 100px;
  .certificate__main_heading{
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    text-align: center;
  }
.certificate {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 80px;
  width: 100vw;
  flex-wrap: wrap;
  .certificate__wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px;
    .certificate__image {
      background-size: contain !important;
      background-repeat: no-repeat !important;
      width: 495px;
      height: 338px;
      @media screen and (max-width: 1300px) {
        width: 295px;
        height: 238px;
      }
    }

    .certificate__date {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #002B9A;
    }

    .certificate__heading {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }

    .certificate__content {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      width: 495px;
      height: 338px;
      @media screen and (max-width: 1300px) {
        width: 295px;
        height: 238px;
      }
    }
  }
}
}
