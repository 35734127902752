.page_404 {
  margin-top: 100px;
  width: 100vw;
  height: 100vh;
  padding: 50px 0;
  background: #fff;
  font-family: 'Arvo', serif;
  img {
    width: 100%;
  }
}
.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  h1 {
    font-size: 80px;
  }
  h3 {
    font-size: 80px;
  }
}
.link_404 {
  text-decoration: none!important;
  color: #fff!important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
