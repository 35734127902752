/*
 * STYLES
 */

.about__Main {
  display: flex;
  flex-direction: column;
  hr.sep-3 {
    border: none;
    height: 3px;
    background-image: linear-gradient(to right, #f0f0f0, #8f8f8f, #f0f0f0);
  }
  hr.sep-3::after {
    content: '§';
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(60deg);
    transform-origin: 50% 50%;
    padding: 1rem;
    background-color: white;
  }
  .indicator__wrapper{
    width: 100vw;
    display: flex;
    justify-content: center;
    .arrows {
      width: 60px;
      height: 72px;
      position: relative;
      margin-left: -30px;
      bottom: 20px;
    }

    .arrows path {
      stroke: #2994D1;
      fill: transparent;
      stroke-width: 1px;
      animation: arrow 2s infinite;
      -webkit-animation: arrow 2s infinite;
    }

    @keyframes arrow
    {
      0% {opacity:0}
      40% {opacity:1}
      80% {opacity:0}
      100% {opacity:0}
    }

    @-webkit-keyframes arrow /*Safari and Chrome*/
    {
      0% {opacity:0}
      40% {opacity:1}
      80% {opacity:0}
      100% {opacity:0}
    }

    .arrows path.a1 {
      animation-delay:-1s;
      -webkit-animation-delay:-1s; /* Safari 和 Chrome */
    }

    .arrows path.a2 {
      animation-delay:-0.5s;
      -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
    }

    .arrows path.a3 {
      animation-delay:0s;
      -webkit-animation-delay:0s; /* Safari 和 Chrome */
    }
}
  .about__main-homepage {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 60vh;
    width: 100vw;

    .headingMainBackground {
      display: flex;
      padding-top: 230px;
      justify-content: center;
      font-weight: 600;
      font-size: 64px;
      line-height: 96px;
      @media screen and(max-width: 1800px) {
        padding-top: 150px;
      }
    }

    .contentMainBackground {
      display: flex;
      padding-top: 30px;
      justify-content: center;
      font-weight: 600;
      font-size: 42px;
      line-height: 96px;
      @media screen and(max-width: 1800px) {
        font-size: 32px;
        padding-top: 10px;
      }
      @media screen and(max-width: 747px) {
        font-size: 22px;
        padding-top: 10px;
        text-align: center;
        line-height: 30px;
      }
    }
  }

  .main__card {
    display: flex;
    flex-direction: column;
    margin: 50px;
    padding: 30px;
    align-items: center;
    @media screen and (max-width: 480px) {
      margin: 20px;
    }

    .heading {
      font-weight: 600;
      font-size: 40px;
      color: #18161D;
      margin: 30px 30px 0 30px;
      text-align: center;
      @media screen and (max-width: 772px) {
        font-size: 24px;
        margin: 10px;
      }
    }

    .subHeading {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      color: #18161D;
      margin: 10px;
      @media screen and (max-width: 772px) {
        font-size: 16px;
        margin: 10px;
      }
    }

    .main__card-Wrapper {
      display: flex;
      flex-wrap: wrap;
      height: 900px;
      overflow-y: scroll;
      margin: 20px;
      justify-content: center;

      &::-webkit-scrollbar {
        background-color: #6b7688;
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #6b7688;
        border-radius: 20px;
      }

      .card-wrapper {
        margin: 10px;
        display: flex;
        flex-direction: column;
        width: 434px;
        height: fit-content;
        cursor: pointer;
        @media screen and (max-width: 1074px) {
          width: 343px;
        }
        @media screen and (max-width: 892px) {
          width: 250px;
        }

        .description_heading{
          font-size: 20px;
          font-weight: 600;
          margin: 10px 0;

        }
        .company_heading{
          font-size: 20px;
          font-weight: 600;
          margin: 10px 0;
        }
        .company {
          font-weight: 500;
          font-size: 16px;
          line-height: 36px;
          color: #ac190c;
          margin: 10px 0;
          .read-more-less--more--agency{
            color: #002B9A;
          }
          .read-more-less--less--agency{
            color: #002B9A;
          }
        }
        .tag {
          display: flex;

          .project__year {
            font-weight: 500;
            font-size: 12px;
            background: rgba(0, 43, 154, 0.8);
            border-radius: 20px;
            padding: 10px;
            color: #FFFFFF;
          }
          .status {
            font-weight: 500;
            font-size: 12px;
            background: rgba(58, 182, 103, 0.8);
            border-radius: 20px;
            padding: 10px;
            margin: 0 10px;
            color: #FFFFFF;
          }
        }

        .project__image {
          background-size: cover !important;
          background-repeat: no-repeat !important;
          width: 434px;
          height: 322px;
          border-radius: 20px;
          @media screen and (max-width: 1074px) {
            width: 343px;
            height: 222px;
          }
          @media screen and (max-width: 892px) {
            width: 250px;
            height: 180px;
          }
        }

        .project__description {
          font-weight: 400;
          font-size: 16px;
          line-height: 36px;
          .read-more-less--more{
            color: #002B9A;
          }
          .read-more-less--less{
            color: #002B9A;
          }
        }



      }
    }
  }
}
