/*
 * STYLES
 */

.process__main {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  div:nth-child(1) {
    font-size: 14px;
    color: #748FB5;
    font-weight: 800;
    @media screen and (max-width: 679px) {
      font-size: 12px;
    }
  }

  div:nth-child(2) {
    font-size: 48px;
    font-weight: 600;
    color: #272E35;
    @media screen and (max-width: 679px) {
      font-size: 32px;
    }
  }

  .process__content-card {
    display: flex;
    width: 100vw;
    height: 250px;
    margin-top: 50px;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (max-width: 1560px) {
      height: 470px;
    }
    @media screen and (max-width: 679px) {
      height: 800px;
    }

    div {
      height: 280px;
      width: 350px;
      display: flex;
      font-size: 14px;
      flex-direction: column;
      text-align: center;
      align-items: center;
      padding: 15px;
      margin: 20px;
      @media screen and (max-width: 1569px) {
        height: 200px;
        width: 300px;
        padding: 20px;
        margin: 25px;
        font-size: 10px;
      }
      @media screen and (max-width: 1401px) {
        height: 150px;
        width: 250px;
        padding: 20px;
        margin: 25px;
        font-size: 10px;
      }
      @media screen and (max-width: 995px) {
        height: 150px;
        width: 290px;
        padding: 20px;
        margin: 25px;
        font-size: 10px;
      }


      div:nth-child(1) {
        background-repeat: no-repeat !important;
        background-size: contain !important;
        border: none !important;
        width: 50px;
        margin-top: -20px;

      }
    ;

      div:nth-child(3) {
        border: none !important;
        width: 200px;

      }
    ;

      button {
        border-radius: 15px;
        background: #748FB5;
        padding: 10px;
        font-size: 12px;
        font-weight: 700;
        border: none;
        color: #ffffff;
        @media screen and (max-width: 780px) {
          font-size: 10px;
          padding: 8px;
        }
      }
    }

    div:not(:nth-child(4)) {
      border-right: 3px solid rgba(116, 143, 181, 0.15);

    }
    @media screen and (max-width: 1560px) {
    div {
      border-right: 3px solid rgba(116, 143, 181, 0.15);
    }
      }
  }
}
