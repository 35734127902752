/*
 * STYLES
 */

.contactForm__main {
  background: #FAFAFA;
  border-radius: 32px;
  width: 1188px;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 54px 48px;
  @media screen and (max-width: 1215px) {
    width: 1000px;
    height: 600px;
  }
  @media screen and (max-width: 1013px) {
    width: 900px;
    height: 700px;
    padding: 62px !important;
  }
  @media screen and (max-width: 899px) {
    width: 800px;
    height: 800px;
    padding: 62px !important;
  }
  @media screen and (max-width: 642px) {
    width: 400px;
    height: 1000px;
    padding: 10px !important;
  }

  .contactForm__main-heading {
    text-align: center;
    font-weight: 600;
    font-size: 40px;

  }

  .contactForm__main-inputForm {
    display: flex;
    flex-direction: column;
    margin: 30px;
    align-items: center;

    .contactForm__main-infoWrapper {
      display: flex;
      @media screen and (max-width: 642px) {
        flex-direction: column;
      }

      .contactForm__main-name {
        display: flex;
        flex-direction: column;
        margin: 10px;

        label {
          font-weight: 400;
          font-size: 16px;
        }

        input {
          background: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0.07);
          border-radius: 4px;
          width: 400px;
          height: 59px;
          padding: 10px;
          font-weight: 500;
          font-size: 14px;
          @media screen and (max-width: 899px) {
            width: 300px;
          }

          &::placeholder {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }

      .contactForm__main-email {
        display: flex;
        flex-direction: column;
        margin: 10px;

        label {
          font-weight: 400;
          font-size: 16px;
        }

        input {
          background: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0.07);
          border-radius: 4px;
          width: 400px;
          height: 59px;
          padding: 10px;
          font-weight: 500;
          font-size: 14px;
          @media screen and (max-width: 899px) {
            width: 300px;
          }

          &::placeholder {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }

    }

    .contactForm__main-messageWrapper {
      display: flex;
      flex-direction: column;
      margin: 10px;

      label {
        font-weight: 400;
        font-size: 16px;
      }

      textarea {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.07);
        border-radius: 4px;
        width: 820px;
        height: 185px;
        padding: 10px;
        font-weight: 500;
        font-size: 14px;
        @media screen and (max-width: 899px) {
          width: 630px;
        }
        @media screen and (max-width: 642px) {
          width: 300px;
        }
        &::placeholder {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .contactForm__main-contactButton {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      button {
        background: #002B9A;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        color: #FFFFFF;
        border: none;
        width: 166px;
        height: 52px;
      }
    }
  }
}
