/*
 * STYLES
 */

.app__footer-wrapper {
  display: flex;
  flex-direction: column;
  margin: 150px;
  @media screen and (max-width: 823px) {
    margin: 100px 0 0 20px;
  }
  .app__footer-logoWrapper{
    padding: 20px 0 20px 0;
    .app__footer-logo {
      background-repeat: no-repeat !important;
      background-size: contain !important;
      width: 120px;
      height: 80px;
      @media screen and (max-width: 823px) {
        width: 80px;
        height:60px;
      }
    }
  }


  .app__footer-contentWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
    border-bottom: 3px solid rgba(116, 143, 181, 0.15);
    @media screen and (max-width: 823px) {
      flex-direction: column;
    }
    .app__footer-wrapper-content {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #6E757C;
      width: 345px;
      height: 120px;
      @media screen and (max-width: 823px) {
        font-size: 12px;
        width: 350px;
        height: 100px;
        margin-bottom: 30px;
      }
    }

    .app__footer-links {
      display: flex;
      .links {
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;

        margin: 0 20px 0 20px;
        @media screen and (max-width: 1184px) {
          margin: 0 20px 0 0;
        }
        @media screen and (max-width: 823px) {
          font-size: 12px;
        }
        &:hover {
          color: rgb(116, 143, 181);
        }
        color: #6E757C !important;
        &:hover {
          color: var(--secondary-color)!important;
        }
      }
    }
  }

  .app__footer__lower-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .app__footer__lower-copyright {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #6E757C;
    }

    .app__footer__lower-social-media {
      display: flex;
      .socialMediaWrapper {
        cursor: pointer;
        margin: 0 10px 0 10px;
        .socialMedia{
          width: 24px;
          height: 24px;
          background-repeat: no-repeat!important;
          background-size: cover!important;
        }
      }
      a{
        text-decoration: none!important;
        color: #6b7688;
      }
    }
  }
}
