.app__main-capabilities {
  margin: 100px 100px 100px 200px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1400px) {
    margin: 20px;
  }
  @media screen and (max-width: 1145px) {
    flex-direction: column;

  }

  .capabilities__main-image {
    background-repeat: no-repeat !important;
    background-size: contain !important;
    width: 544px;
    height: 384px;
    border-radius: 24px;
    background: #6b7688;
    @media screen and (max-width: 1300px) {
      width: 444px;
      height: 344px;
    }
    @media screen and (max-width: 543px) {
      width: 344px;
      height: 264px;
    }

  }

  .capability__main-contentWrapper {
    margin: 50px 50px 50px 100px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1145px) {
      margin: 0!important;
      text-align: center;
    }
    .heading {
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      color: #18161D;
    }

    .content {
      width: 502px;
      height: 168px;
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      color: #51575D;
      @media screen and (max-width: 543px) {
        width: 444px;
        height: 264px;
      }
      @media screen and (max-width: 433px) {
        width: 344px;
        height: 264px;
      }
    }
  }
}
