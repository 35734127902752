/*
 * STYLES
 */

.project__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100vw;

.project__main-heading{
  margin-top: 100px;
  @media screen and (max-width: 726px) {
    font-size: 18px;
  }
}
  div {
    font-size: 18px;
    color: #6E757C;
    font-weight: 500;
  }

  .project__main-clientWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    justify-content: center;
    margin-left: 100px;
    @media screen and (max-width: 943px) {
      margin-left: 80px;
    }
    @media screen and (max-width: 726px) {
      display: none;
    }

    .project__main-client {
      width: 300px;
      height: 250px;
      @media screen and (max-width: 1316px) {
        width: 250px;
        height: 200px;
      }

      div {
        background-size: contain !important;
        background-repeat: no-repeat !important;
        width: 200px;
        height: 100px;
        @media screen and (max-width: 1316px) {
          width: 150px;
          height: 80px;
        }
      }

    }
  }

  .project__infoWrapper {
    background: rgb(254, 169, 137);
    background: -moz-linear-gradient(90deg, rgba(254, 169, 137, 1) 0%, rgba(153, 180, 219, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(254, 169, 137, 1) 0%, rgba(153, 180, 219, 1) 100%);
    background: linear-gradient(90deg, rgba(254, 169, 137, 1) 0%, rgba(153, 180, 219, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fea989", endColorstr="#99b4db", GradientType=1);
    z-index: 1;
    border-radius: 15px;
    width: 1380px;
    height: 250px;
    margin-top: -20px;
    display: flex;
    box-shadow: none !important;
    @media screen and (max-width: 1468px) {
      width: 1180px;
      height: 200px;
    }
    @media screen and (max-width: 1316px) {
      width: 1000px;
      height: 140px;
    }
    @media screen and (max-width: 1236px) {
      width: 980px;
      height: 140px;
    }
    @media screen and (max-width: 1000px) {
      width: 840px;
      height: 130px;
    }
    @media screen and (max-width: 879px) {
      width: 640px;
      height: 120px;
    }
    @media screen and (max-width: 656px) {
      margin-top: -10px;
      width: 380px;
      height: 120px;
      padding: 5px;
    }
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 72px;
        font-weight: 700;
        color: #272E35;
        @media screen and (max-width: 1316px) {
          font-size: 60px;
        }
        @media screen and (max-width: 1236px) {
          font-size: 54px;
        }
        @media screen and (max-width: 1000px) {
          font-size: 42px;
        }
        @media screen and (max-width: 879px) {
          font-size: 32px;
        }
        @media screen and (max-width: 656px) {
          font-size: 18px;
        }
      }

      button {
        color: #272E35;
        border-radius: 18px;
        background: #ffffff;
        font-size: 14px;
        padding: 10px;
        border: none;
        font-weight: 600;
        margin-bottom: 10px;
        @media screen and (max-width: 1316px) {
          font-size: 12px;
        }
        @media screen and (max-width: 879px) {
         font-size: 12px;
        }
        @media screen and (max-width: 656px) {
          font-size: 10px;
          padding: 12px;
        }
      }
    }

    div:not(:nth-child(3)) {
      border-right: 3px solid rgba(225, 225, 225, 0.5);
    }
  }
  .project__process{
    margin-top: 200px;
  }
}
.project__main-clientCarousel{
  @media screen and (min-width: 726px) {
    display: none ;
  }
  margin-top: 60px;
  display: flex;
  justify-content: center;
  .project__main-clientCarousel{
    margin-top: 50px;
    width: 100vw;
    height: 100px;
    div {
      background-size: contain !important;
      background-repeat: no-repeat !important;
      width: 150px;
      height: 80px;

    }
  }
}
