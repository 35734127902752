/*
 * STYLES
 */

.home__main{


  background-repeat: no-repeat!important;
  background-size: cover!important;


}
.home__main-shade{
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.71) 14.54%, rgba(128, 128, 128, 0.353086) 82.05%, rgba(255, 255, 255, 0) 101.86%) !important;
  display: flex;
  justify-content: space-between;
  transition: all 1s;
  width: 100vw;
  height: 100vh;

}
.home__headingWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
  margin: 80px 20px 0 180px;
  &.large{
    font-size: 38px;
    letter-spacing: 2px;
    @media only screen and (max-width: "896px") {
      font-size: 22px;
    }
    @media only screen and (max-width: "630px") {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: "1420px") {
    margin: 80px 20px 0 140px;

  }
  @media only screen and (max-width: "1200px") {
    margin: 80px 20px 0 100px;
  }
  @media only screen and (max-width: "896px") {
    margin: 40px 20px 0 60px;
    width: 60%;
  }
  @media only screen and (max-width: "630px") {
    margin: 20px 20px 0 40px;
  }
  div{
    font-size: 72px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #ffffff;
    @media only screen and (max-width: "1420px") {
      font-size: 62px;
    }
    @media only screen and (max-width: "1200px") {
      font-size: 52px;
    }
    @media only screen and (max-width: "896px") {
      font-size: 42px;
    }
    @media only screen and (max-width: "630px") {
      font-size: 32px;
    }
  }
  p{
    margin-top: 10px;
    color: #ffffff;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 2px;
    @media only screen and (max-width: "896px") {
      font-size: 14px;
    }
    @media only screen and (max-width: "630px") {
      font-size: 12px;
    }
  }
}
.home__main-imageWrapper{
  width: fit-content;
  height: fit-content;
  @media only screen and (max-width: "630px") {
    margin-left: 290px;
  }
  @media only screen and (max-width: "514px") {
    margin-left: 200px;
  }
  @media only screen and (max-width: "414px") {
    margin-left: 130px;
  }
}
.home__main-image{
  width:800px;
  height: 670px;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  @media only screen and (max-width: "1400px") {
    width: 600px;
    height: 470px;
  }
  @media only screen and (max-width: "1200px") {
    width: 500px;
    height: 370px;
  }
  @media only screen and (max-width: "896px") {
    width: 400px;
    height: 270px;
  }
  @media only screen and (max-width: "630px") {
    width: 300px;
    height: 270px;
  }


}
.item {
  transition: opacity 1s ease-in-out !important;
}
