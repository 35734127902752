/*
 * STYLES
 */

.service__main {
  display: flex;
  flex-direction: column;


  .service__main-homepage {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 60vh;
    width: 100vw;

    div {
      display: flex;
      padding-top: 230px;
      justify-content: center;
      font-weight: 600;
      font-size: 64px;
      line-height: 96px;
    }
  }

  .service__wrapper {
    width: 100vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    .services {
      font-weight: 400;
      font-size: 16px;
      line-height: 38px;
      color: #51575D;
      width: 70vw;
      height: auto;
      margin: 60px 0 40px 0;
    }

    .service__main-point {
      display: flex;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: #51575D;
      width: 70vw;
      justify-content: space-between;
    }
  }
}
