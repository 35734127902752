/*
 * STYLES
 */
.job__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .job__main-heading {
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    margin-top: 150px;
    @media screen and (max-width: 374px) {
      font-size: 32px;
    }
  }

  .job__main-subHeading {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    width: 623px;
    margin-top: 10px;
    @media screen and (max-width: 662px) {
      width: 344px;
    }
  }

  .job__main-scroller {
    height: 600px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    width: 100vw;

    div {
      .job__main-opportunityWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 50px;

        .job__main-opportunities {
          .job__opening-button {
            background: transparent;
            border-radius: 10px;
            padding: 10px;
            border: 2px solid #4EC251;
            color: #4EC251;
            &:hover{
              background: #4EC251;
              color: #FFFFFF;
            }
            @media screen and (max-width: 453px) {
              font-size: 12px;
              padding: 5px;
              margin-top: 20px;
            }
          }

          cursor: pointer;
          display: flex;
          justify-content: space-between;
          background: rgba(255, 255, 255, 0.49);
          box-shadow: 0 4px 28px rgba(0, 0, 0, 0.16);
          border-radius: 15px;
          width: 1244px;
          height: 100px;
          margin: 10px;
          padding: 20px;
          @media screen and (max-width: 1264px) {
            width: 1044px;
          }
          @media screen and (max-width: 1059px) {
            width: 844px;
          }
          @media screen and (max-width: 847px) {
            width: 644px;
          }
          @media screen and (max-width: 662px) {
            width: 444px;
          }
          @media screen and (max-width: 453px) {
            width: 344px;
            height: 150px;
          }

          .job__main-opportunitiesContent {
            display: flex;
            flex-direction: column;

            .job__main-department {
              color: #4EC251;
              font-weight: 400;
              font-size: 14px;
            }

            .job__main-position {
              font-weight: 400;
              font-size: 18px;
              color:#030303;
            }

            .job__main-location {
              color: #979797;
              font-weight: 400;
              font-size: 15px;
            }
          }
        }
      }
    }

  }

  .job__main-scroller::-webkit-scrollbar {
    display: none;
  }

}
