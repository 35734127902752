.app__main-service {
  margin: 150px 150px 10px 250px;
  @media screen and (max-width: 1422px) {
    margin: 100px 100px 10px 100px;
  }
  @media screen and (max-width: 1296px) {
    margin: 80px 80px 10px 80px;
  }
  @media screen and (max-width: 1000px) {
    margin: 50px 50px 10px 50px;
  }
  @media screen and (max-width: 850px) {
    margin: 50px 20px 20px 20px;
  }

  .points {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 850px) {
      flex-direction: column;
      align-items: center;
    }
    .service__images {
      width: 500px;
      height: 500px;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      border-radius: 20px;
      margin-right: 40px;
      @media screen and (max-width: 1138px) {
        width: 400px;
        height: 400px;
      }
      @media screen and (max-width: 1000px) {
        width: 350px;
        height: 350px;
      }
      @media screen and (max-width: 436px) {
        width: 300px;
        height: 300px;
      }
    }

    ul {
      margin: 40px;

      li {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: #51575D;
        margin: 10px;
        @media screen and (max-width: 559px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .content {
    width: 1000px;
    height: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #51575D;
    margin: 40px;
    @media screen and (max-width: 1124px) {
      width: 850px;
    }
    @media screen and (max-width: 1000px) {
      width: 800px;
    }
    @media screen and (max-width: 890px) {
      width: 750px;
    }
    @media screen and (max-width: 820px) {
      width: 700px;
      margin: 20px;
    }
    @media screen and (max-width: 750px) {
      width: 650px;
      margin: 20px 20px 20px 10px;
    }
    @media screen and (max-width: 684px) {
      width: 600px;
    }
    @media screen and (max-width: 624px) {
      width: 550px;
    }
    @media screen and (max-width: 576px) {
      width: 500px;
    }
    @media screen and (max-width: 526px) {
      width: 450px;
    }
    @media screen and (max-width: 488px) {
      width: 400px;
      text-align: center;
    }
    @media screen and (max-width: 436px) {
      width: 350px;
    }
    @media screen and (max-width: 400px) {
      width: 300px;
    }
  }

  .headingWrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: 488px) {
      justify-content: center;
    }

    .icon {
      width: 82px;
      height: 82px;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      @media screen and (max-width: 559px) {
        width: 62px;
        height: 62px;
      }
    }

    .heading {
      margin-left: 20px;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      @media screen and (max-width: 559px) {
        font-size: 18px;
      }
    }
  }


}


.buttonWrapper {
  width: 100vw;
  display: flex;
  justify-content: center;

  button {
    width: 143px;
    height: 56px;
    border: none;
    background: #002B9A;
    border-radius: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

  }
}

.achievement__contentWrapper {
  display: flex;
  justify-content: center;
  margin: 40px;
  @media screen and (max-width: 594px) {
    text-align: center;
  }

  .achievement__content {
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
    color: #51575D;
    width: 1367px;
    height: 154px;
    @media screen and (max-width: 594px) {
      height: 240px;
      font-size: 18px;
    }
  }
}
