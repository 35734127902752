.app__main-conclusion{
  text-align: left;
  margin: 80px;
  @media screen and (max-width: 900px) {
    margin: 20px;
  }
  .heading{
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    color: #18161D;
    margin: 30px;
    @media screen and (max-width: 1004px) {
      font-size: 32px;
      margin: 15px;
    }
    @media screen and (max-width: 600px) {
      font-size: 28px;
      margin: 5px;
    }
  }
  .content{
    margin: 30px;
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
    color: #51575D;
    @media screen and (max-width: 1004px) {
      font-size: 14px;
      margin: 15px;
    }
    @media screen and (max-width: 600px) {
      font-size: 10px;
      margin: 5px;
    }
  }
}
